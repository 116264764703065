import { API_TYPE_CALL_API, API_TYPE_DOWNLOAD_FILE, METHOD, X_UNIT_GROUP_ID, SERVER } from 'shared/constants';
import api from './api';

const CONSTRUCTION_API_GETS = `${SERVER}/form-data/v1/construction-registration`;
const CONSTRUCTION_API_EXPORT = `${SERVER}/form-data/v1/construction-registration/export`;
const CONSTRUCTION_API_REGISTRATION = `${SERVER}/form-data/v1/construction-registration`;
const CONSTRUCTION_API_UPDATE = (id) => `${SERVER}/form-data/v1/construction-registration/${id} `;
const CONSTRUCTION_API_DETAIL = (id) => `${SERVER}/form-data/v1/construction-registration/${id} `;
const CONSTRUCTION_API_REMOVE = (id) => `${SERVER}/form-data/v1/construction-registration/${id}`;
const CONSTRUCTION_API_APPROVE = (id) => `${SERVER}/form-data/v1/construction-registration/${id}/approve`;
const CONSTRUCTION_API_CANCEL = (id) => `${SERVER}/form-data/v1/construction-registration/${id}/cancel`;
const CONSTRUCTION_API_DEPOSIT = (id) => `${SERVER}/form-data/v1/construction-registration/${id}/deposit/deposit`;
const CONSTRUCTION_API_REFUND = (id) => `${SERVER}/form-data/v1/construction-registration/${id}/deposit/refund`;
const CONSTRUCTION_API_IN_PROGRESS = (id) => `${SERVER}/form-data/v1/construction-registration/${id}/in-progress`;
const CONSTRUCTION_API_COMPLETE = (id) => `${SERVER}/form-data/v1/construction-registration/${id}/complete`;
const CONSTRUCTION_COUNT_BADGES = `${SERVER}/form-data/v1/construction-registration/count-badges`;
const CONSTRUCTION_API_REGULATION = `${SERVER}/form-data/v1/construction-registration/regulation`;

export const getConstructionService = async ({ filter, limit, page, idBuilding }) => {
  const headers = { [X_UNIT_GROUP_ID]: idBuilding };
  const response = await api({
    type: API_TYPE_CALL_API,
    payload: {
      apiUrl: CONSTRUCTION_API_GETS,
      method: METHOD.GET,
      options: {
        params: {
          ...filter,
          page,
          limit,
        },
        headers,
      },
    },
  });
  return response;
};

export const getRegulationService = async ({ filter, limit, page, idBuilding }) => {
  const headers = { [X_UNIT_GROUP_ID]: idBuilding };
  const response = await api({
    type: API_TYPE_CALL_API,
    payload: {
      apiUrl: CONSTRUCTION_API_REGULATION,
      method: METHOD.GET,
      options: {
        params: {
          ...filter,
          page,
          limit,
        },
        headers,
      },
    },
  });
  return response;
};

export const exportConstructionService = async (filterObj, idBuilding) => {
  const headers = { [X_UNIT_GROUP_ID]: idBuilding };
  const response = await api({
    type: API_TYPE_DOWNLOAD_FILE,
    payload: {
      apiUrl: CONSTRUCTION_API_EXPORT,
      method: METHOD.GET,
      options: { params: { ...filterObj }, headers },
    },
  });
  return response;
};

export const addConstructionService = async (body, idBuilding) => {
  const headers = { [X_UNIT_GROUP_ID]: idBuilding };
  const response = await api({
    type: API_TYPE_CALL_API,
    payload: {
      apiUrl: CONSTRUCTION_API_REGISTRATION,
      method: METHOD.POST,
      body,
      options: {
        headers,
      },
    },
  });
  return response;
};

export const updateConstructionService = async (id, body, idBuilding) => {
  const headers = { [X_UNIT_GROUP_ID]: idBuilding };
  const response = await api({
    type: API_TYPE_CALL_API,
    payload: {
      apiUrl: CONSTRUCTION_API_UPDATE(id),
      method: METHOD.PUT,
      body,
      options: {
        headers,
      },
    },
  });
  return response;
};

export const getConstructionDetailService = async (id, idBuilding) => {
  const headers = { [X_UNIT_GROUP_ID]: idBuilding };
  const response = await api({
    type: API_TYPE_CALL_API,
    payload: {
      apiUrl: CONSTRUCTION_API_DETAIL(id),
      method: METHOD.GET,
      options: {
        headers,
      },
    },
  });
  return response;
};

export const removeConstructionService = async (id, body, idBuilding) => {
  const headers = { [X_UNIT_GROUP_ID]: idBuilding };
  const response = await api({
    type: API_TYPE_CALL_API,
    payload: {
      apiUrl: CONSTRUCTION_API_REMOVE(id),
      method: METHOD.DELETE,
      body,
      options: {
        headers,
      },
    },
  });
  return response;
};

export const approveConstructionService = async (id, body, idBuilding) => {
  const headers = { [X_UNIT_GROUP_ID]: idBuilding };
  const response = await api({
    type: API_TYPE_CALL_API,
    payload: {
      apiUrl: CONSTRUCTION_API_APPROVE(id),
      method: METHOD.PUT,
      body,
      options: {
        headers,
      },
    },
  });
  return response;
};

export const rejectConstructionService = async (id, body, idBuilding) => {
  const headers = { [X_UNIT_GROUP_ID]: idBuilding };
  const response = await api({
    type: API_TYPE_CALL_API,
    payload: {
      apiUrl: CONSTRUCTION_API_CANCEL(id),
      method: METHOD.PUT,
      body,
      options: {
        headers,
      },
    },
  });
  return response;
};

export const depositConstructionService = async (id, body, idBuilding) => {
  const headers = { [X_UNIT_GROUP_ID]: idBuilding };
  const response = await api({
    type: API_TYPE_CALL_API,
    payload: {
      apiUrl: CONSTRUCTION_API_DEPOSIT(id),
      method: METHOD.PUT,
      body,
      options: {
        headers,
      },
    },
  });
  return response;
};

export const refundConstructionService = async (id, body, idBuilding) => {
  const headers = { [X_UNIT_GROUP_ID]: idBuilding };
  const response = await api({
    type: API_TYPE_CALL_API,
    payload: {
      apiUrl: CONSTRUCTION_API_REFUND(id),
      method: METHOD.PUT,
      body,
      options: {
        headers,
      },
    },
  });
  return response;
};

export const inProgressConstructionService = async (id, body, idBuilding) => {
  const headers = { [X_UNIT_GROUP_ID]: idBuilding };
  const response = await api({
    type: API_TYPE_CALL_API,
    payload: {
      apiUrl: CONSTRUCTION_API_IN_PROGRESS(id),
      method: METHOD.PUT,
      body,
      options: {
        headers,
      },
    },
  });
  return response;
};

export const completeConstructionService = async (id, body, idBuilding) => {
  const headers = { [X_UNIT_GROUP_ID]: idBuilding };
  const response = await api({
    type: API_TYPE_CALL_API,
    payload: {
      apiUrl: CONSTRUCTION_API_COMPLETE(id),
      method: METHOD.PUT,
      body,
      options: {
        headers,
      },
    },
  });
  return response;
};

export const countBadgesConstructionService = async (idBuilding) => {
  const response = await api({
    type: API_TYPE_CALL_API,
    payload: {
      apiUrl: CONSTRUCTION_COUNT_BADGES,
      method: METHOD.GET,
      options: {
        headers: {
          [X_UNIT_GROUP_ID]: idBuilding,
        },
      },
    },
  });
  return response;
};
